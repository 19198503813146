<template>
  <div class="number_container">
    <div class="occupancy_value">{{ formatOccupancyValue() }}</div>
    <div class="max_value">{{ formatMaxValue() }}</div>
  </div>
  <div class="site">{{ site }}</div>
</template>

<script>
import Axios from "axios";

export default {
  name: "Occupancy",
  data() {
    return {
      site: "",
      max_value: 0,
      max_value_prefix: "Maximum ",
      max_value_suffix: " persons allowed.",
      occupancy_value: 0,
      occupancy_value_prefix:"",
      occupancy_value_suffix:"",
      refresh: 500,
      box_border_color: "#0051ba",
      box_border_size: "1px",
      box_border_style: "solid",
      box_bg_color: "#0051ba",
      box_occupancy_value_color: "#ffda1a",
      box_max_value_color: "#ffda1a",
      box_site_color: "#0051ba",
      box_occupancy_value_font_size: "150px",
      box_max_value_font_size: "30px",
      box_site_font_size: "36px",
      box_max_value_text_align: "center",
      box_occupancy_value_text_align: "center",
      box_site_value_text_align: "center",
      box_text_align: "center",
      box_width: "auto",
      box_height: "auto",
      box_padding: "50px",
      box_occupancy_value_font_weight: "bold",
      box_max_value_font_weight: "bold",
      box_site_font_weight: "bold"
    };
  },
  mounted() {
    this.getOccupancy();
  },
  methods: {
    formatMaxValue() {
      return this.max_value_prefix + this.max_value + this.max_value_suffix;
    },
    formatOccupancyValue(){
      return this.occupancy_value_prefix + this.occupancy_value + this.occupancy_value_suffix
    },
    getURLParameter() {
      const params = new URLSearchParams(window.location.search);
      return params;
    },
    fetchData(domain, branchid, sessionid) {
      Axios({
        method: "get",
        url: "https://occupancyapi.xpandretail.com/get-occupancy/" + domain + "/" + branchid + "/" + sessionid,
      }).then((response) => {
        console.log("Data successfully retrieved");
        const params = this.getURLParameter();
        if(params.has("site")){
          this.site = params.get("site");
        }
        else
        {
          this.site = response.data.branch.name;
        }

        if(params.has("max_value")){
          this.max_value = params.get("max_value");
        }
        else{
          this.max_value =
          response.data.branch.settings.people_count.crowd_control.max_count;
        }
        
        if(params.has("occupancy_value")){
          this.occupancy_value = params.get("occupancy_value")
        }
        else{
          this.occupancy_value = response.data.status.people_count.current;
        }
      });
    },
    getOccupancy() {
      const params = this.getURLParameter();
      const branchid = params.get("branchid");
      const domain = params.get("domain");
      const sessionid = params.get("sessionid");
      if (params.has("refresh")) {
        this.refresh = params.get("refresh");
      }
      if (params.has("box_border_color")) {
        this.box_border_color = params.get("box_border_color");
      }
      if (params.has("box_border_size")) {
        this.box_border_size = params.get("box_border_size");
      }
      if (params.has("box_bg_color")) {
        this.box_bg_color = params.get("box_bg_color");
      }
      if (params.has("box_occupancy_value_color")) {
        this.box_occupancy_value_color = params.get("box_occupancy_value_color");
      }
      if (params.has("box_max_value_color")) {
        this.box_max_value_color = params.get("box_max_value_color");
      }
      if (params.has("box_site_color")) {
        this.box_site_color = params.get("box_site_color");
      }
      if (params.has("box_occupancy_value_font_size")) {
        this.box_occupancy_value_font_size = params.get("box_occupancy_value_font_size");
      }
      if (params.has("box_max_value_font_size")) {
        this.box_max_value_font_size = params.get("box_max_value_font_size");
      }
      if (params.has("box_site_font_size")) {
        this.box_site_font_size = params.get("box_site_font_size");
      }
      if (params.has("max_value_prefix")){
        this.max_value_prefix = params.get("max_value_prefix");
      }
      if (params.has("max_value_suffix")){
        this.max_value_suffix = params.get("max_value_suffix");
      }
      if (params.has("occupancy_value_prefix")){
        this.occupancy_value_prefix = params.get("occupancy_value_prefix");
      }
      if (params.has("occupancy_value_suffix")){
        this.occupancy_value_suffix = params.get("occupancy_value_suffix");
      }

      if (params.has("box_max_value_text_align")){
        this.box_max_value_text_align = params.get("box_max_value_text_align");
      }

      if (params.has("box_occupancy_value_text_align")){
        this.box_occupancy_value_text_align = params.get("box_occupancy_value_text_align");
      }

      if (params.has("box_site_value_text_align")){
        this.box_site_value_text_align = params.get("box_site_value_text_align");
      }

      if (params.has("box_text_align")){
        this.box_text_align = params.get("box_text_align");
      }

      if (params.has("box_width")){
        this.box_width = params.get("box_width");
      }

      if (params.has("box_height")){
        this.box_height = params.get("box_height");
      }

      if (params.has("box_padding")){
        this.box_padding = params.get("box_padding");
      }

      if (params.has("box_occupancy_value_font_weight")){
        this.box_occupancy_value_font_weight = params.get("box_occupancy_value_font_weight");
      }

      if (params.has("box_max_value_font_weight")){
        this.box_max_value_font_weight = params.get("box_max_value_font_weight");
      }

      if (params.has("box_site_font_weight")){
        this.box_site_font_weight = params.get("box_site_font_weight");
      }

      if (params.has("box_border_style")){
        this.box_border_style = params.get("box_border_style");
      }

      setInterval(() => {
        this.fetchData(domain, branchid, sessionid);
      }, this.refresh);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.number_container {
  border: v-bind(box_border_size) v-bind(box_border_style) v-bind(box_border_color);
  width: v-bind(box_width);
  height: v-bind(box_height);
  text-align: v-bind(box_text_align);
  padding: v-bind(box_padding);
  background-color: v-bind(box_bg_color);
}
.occupancy_value {
  font-size: v-bind(box_occupancy_value_font_size);
  font-weight: v-bind(box_occupancy_value_font_weight);
  color: v-bind(box_occupancy_value_color);
  text-align: v-bind(box_occupancy_value_text_align);
}
.max_value {
  font-size: v-bind(box_max_value_font_size);
  font-weight: v-bind(box_max_value_font_weight);
  color: v-bind(box_max_value_color);
  text-align: v-bind(box_max_value_text_align);
}

.site {
  font-size: v-bind(box_site_font_size);
  font-weight: v-bind(box_site_font_weight);
  color: v-bind(box_site_color);
  text-align: v-bind(box_site_value_text_align);
}
</style>

